.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: #007C92 !important;
}

a:link {
  color: #007C92 !important;
}

a​:active {
  color: #5E2750 !important;
}

a​:visited {
  color: #007C92 !important;
}

a​:hover {
  color: #5E2750 !important;
}

.css-1d3bbye {
  justify-content: space-between;
  align-items: center;
}
